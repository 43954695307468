import React, { useContext } from "react"
import styled from 'styled-components'
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "@components/Seo";
import FAQContent from '@components/schemas/seoAudioSexStories.json'
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;

`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`

const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`


const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`
const FAQItemHeadline = styled.h3`
  font-size: 2rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const content = {
  "female-dominance":{
    "subtitle":"Femdom Audio Sex:",
    "text":"This intense Audio Sex allows you to express yourself in a new way while exploring soft bondage with your lover. You have given your partner instructions to wait for you naked and blindfolded when you get home from work, and he obeys. Narrated by an erotic British accent, you get to hear everything that’s going through your submissive’s head while you take control of him for one whole night. This 9 minute Audio Sex is perfect for a couple’s first adventure into submission and bondage.",
    "rank":10
  },
  "neighbourly-desires-voyeur-sex":{
    "subtitle":"Stranger Audio Sex:",
    "text":"To heal after a breakup, you decide to go on a whirlwind trip to Italy. You’re wandering around the streets of Rome, marveling at the beauty of the city when you see a small little restaurant and decide to get something to eat. A waiter guides you to your seat and you immediately get lost in his smile. He has warm, brown eyes and a strong jawline, and you need him in a way you haven’t felt in a long time. This 20 minute sizzling Audio Sex is the perfect fantasy to get lost in when you have the time for some sexy self care.",
    "rank":9
  },
  "basement-threesome":{
    "subtitle":"Voyerism Audio Porn:",
    "text":"You and your neighbor have been playing this peep-show game for weeks. You love teasing him through your window, and tonight you’re going to take things to the next level. You wander into your bedroom and glance at his window, your heart sinking momentarily when you see only darkness. Then, a light flips on, and a smile flashes across your face. It’s time for the show to begin. This fun and playful 21 minute Audio Sex is every voyeur’s dream.",
    "rank":8
  },
  "lecturer-2":{
    "subtitle":"Lesbian Audio Sex:",
    "text":"Your first appointment with your sexy Lecturer proved to be equally pleasurable for you both, with flirting and foreplay that gave you a taste of what’s to come. After your first same-sex encounter, you’re hooked on her. Part II of this story has you bringing your sexy Lecturer a naughty little gift after class to thank her for your last meeting. This 9-minute lesbian Audio Sex is everyone’s naughtiest teacher-student fantasy come true.",
    "rank":7
  },
  "mountaintop-adventure":{
    "subtitle":"Public Audio Sex:",
    "text":"You and your lover decide to go on a romantic hike. The rough terrain has been a bit of a challenge, but the incredible view from the mountaintop takes your breath away. You’re both exhausted, but the view and the mood sweeps you away and you get lost in each other, finally living out that outdoor sex fantasy you’ve both always wanted. This 22-minute Audio Sex is perfect for couples who want to share an outdoor erotic fantasy.",
    "rank":6, 
  },
  "anal-adventure":{
    "subtitle":"Anal Audio Sex:",
    "text":"You’re on a date with a guy you really like, and it’s going well. He invites you over for dinner and you’re excited to see his apartment and where you may eventually spend the night together. He is fetching you a glass of wine and you wander around the apartment, looking at various parts of his life on display. You come across a bookshelf and examine all the books and photos there. You mindlessly pull open a drawer that’s ajar and take a peek inside. It’s a drawer full of sex toys. One of them is a butt plug. In this 10 minute Audio Sex, you and your new lover explore the fun world of anal play together after you realize he’s as kinky as you hoped he’d be.",
    "rank":5
  },
  "shower-for-two":{
    "subtitle":"Stranger Audio Sex:",
    "text":"This wet and wild follow-up to “<a href='https://audiodesires.com/story/sauna-sex/'>Hot Sauna</a>” has you at the gym with that same sexy stranger - and you’re not done having fun, yet. You suggest the two of you take a shower after getting so hot and sweaty in the sauna. The thrill of someone catching you at any moment is almost as powerful as the way he claims you as his own. This 13-minute thrill ride is the perfect foreplay for shower sex with your partner.",
    "rank":4
  },
  "new-professor-part-2":{
    "subtitle":"Professor-Student Audio Sex:",
    "text":"After your first voyage into bondage and submission is interrupted, part two of this “naughty professor” story has you visiting him in his office after being interrupted the last time you went to see him. Told entirely from the professor’s point of view, you get a little peek into all of his dirty thoughts about you as you tease him and please him in his office. This 24-minute sizzler story is perfect for explosive orgasms with a slow build.",
    "rank":3
  },
  "yoga-instructor-2":{
    "subtitle":"Public Audio Sex:",
    "text":"Part I of this yoga-centric audio sex series has you noticing just how sexy your new yoga instructor is - and allowing him to give you something of a private lesson. Part II has you coming back for more, teasing your sexy new yoga instructor in nothing but a red sports bra, tiny red shorts, and nothing underneath. “How are you,” he asks, and you respond, “I’ve been incredibly tense all week...I was hoping you could work me harder this time.” You know full well how",
    "rank":2
  },
  "secretly-camping":{
    "subtitle":"Public Audio Sex",
    "text":"Deep in a national park, you and your partner can’t seem to keep your hands off each other. Your friends decide to go on a hike to gather wood for the fire. Now’s your chance. Your lover opens their mouth to speak but your fingers quickly press against his lips - there’s not a lot of time, and you don’t want to waste it talking. This 9-minute story is perfect for a sensual quickie between familiar lovers.",
    "rank":1
  },

}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "Top 10 Audio Sex Stories You Absolutely Need to Listen to in 2021",
  "image": [
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-sex-stories-audiodesires-1-1.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-sex-stories-audiodesires-4-3.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-sex-stories-audiodesires-16-9.jpg"
   ],
  "datePublished": "2020-12-02",
  "dateModified": "2020-12-02",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": {
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/erotic-audio-stories/"
 }
}

const EroticAudioStories = (props) => {
  const lang = props.pageContext.lang
  const {user} = useContext(FirebaseContext);

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
    <Header
      boxLayout={false}
      playerMode={"small"}
      user={user}
      alwaysShow={true}
    />
    <ArticleWrapper>
      <Seo
        title={"Top 10 Audio Sex Stories to listen to in 2021"}
        description={
          "Erotic audio stories for women ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"
        }
        structuredData={[articleSchema, FAQContent]}
      />
      <ContentPaddingWrapper>
        <MainHeading>{articleSchema.headline}</MainHeading>
        <SubHeadline>Relax, listen, climax - it’s that easy. </SubHeadline>
        <MetaData>Last Updated on December 2, 2020 </MetaData>
        <MetaData>by {articleSchema.author.name} </MetaData>
        <ArticleHeaderImage
          src={headerImage}
          alt="Audio Sex by Audiodesires"
        />
        <FirstText>
          Audio sex allows you to create and explore a world entirely your own
          through the narration of audio sex. Instead of watching someone else
          experience pleasure, you (and your partner) can enjoy moderately
          spicy and always sexy stories that are crafted to give you the
          ultimate erotic experience.
          <br />
          <br />
          Audiodesires offers various types of audio, from soft and sensual to
          bondage and fetishes. All of our stories are made for women and
          couples to explore together or separately. Our professional
          narrators take you on a pleasurable journey through a story of your
          choosing. We even incorporate real sounding sexual cues like
          moaning, heavy breathing and kissing. You can explore all kinds of
          new kinks, sexual adventures and each other in ways that you’ve
          never experienced before.
          <br />
          <br />
          Below are the top 10 audio sex stories you will find on Audiodesires
          this year. These rankings are created based on ratings they’ve been
          given by listeners just like you.
        </FirstText>
        {Stories.sort((a, b) => b.rank - a.rank).map(story => (
          <ListItemWrapper key={story.rank}>
            <ItemImageWrapper>
              <Link to={`/story/${story.en.slug}`}>
                <GatsbyImage
                  image={story.localImage.childImageSharp.gatsbyImageData}
                  alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
              </Link>
            </ItemImageWrapper>
            <ItemTextColumn>
              <ItemHeadline>
                <EroticAudio>{story.subtitle}</EroticAudio>
                {story.rank}. {story.en.title}
              </ItemHeadline>
              <ItemMetaInfo>
                Narrator:{" "}
                <StyledLink to={`/voice/${story[lang].voices[0].name.toLowerCase()}`}>
                  {story[lang].voices[0].name}
                </StyledLink>
              </ItemMetaInfo>
              <ItemText
                dangerouslySetInnerHTML={{
                  __html: content[story.en.slug].text,
                }}
              />
              <ItemCta>
                Listen to{" "}
                <StyledLink to={`/story/${story.en.slug}`}>
                  {story.en.title}
                </StyledLink>
                {} now.
              </ItemCta>
            </ItemTextColumn>
          </ListItemWrapper>
        ))}
        <HeaderTitle center>
          Frequently Asked Questions (FAQ) about Erotic Audio Stories
        </HeaderTitle>
        {FAQContent.mainEntity.map((edge, index) => (
          <ListItemWrapper key={index}>
            <ItemTextColumn>
              <FAQItemHeadline>{edge.name}</FAQItemHeadline>
              <ItemText
                dangerouslySetInnerHTML={{ __html: edge.acceptedAnswer.text }}
              />
            </ItemTextColumn>
          </ListItemWrapper>
        ))}
      </ContentPaddingWrapper>
    </ArticleWrapper>
  </>;
}

export default EroticAudioStories
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["female-dominance", "neighbourly-desires-voyeur-sex", "basement-threesome", "lecturer-2", "mountaintop-adventure", "anal-adventure", "shower-for-two", "new-professor-part-2", "yoga-instructor-2", "secretly-camping"]}}}
  ) {
    nodes {
      ...seoBlogPostStoryInfo 
    } 
  }
}
`